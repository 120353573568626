@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
@layer base{
    body{
        @apply font-[Railway];
    }
    li{
        @apply px-4;
        @apply cursor-pointer;
    }
}
.content-div{
    background-repeat: no-repeat;
    background-size:cover;
    background-position:center;
    height:250px;
}
.content-div:hover{
    background-image:linear-gradient(
        to right,
        rgba(112,157,255,0.3),
        hsla(242,50%,40%,0.6)
    
    )!important;

}